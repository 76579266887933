document.addEventListener('turbolinks:load', function () {
    if ($('.payments.show').length === 0) {
        return false
    }

    var token = $("meta[name='csrf-token']").attr('content');
    var form = document.getElementById('payment-form');
    var paymentId = form.className
    var cardDetailField = document.getElementById('enter-card-details')

    $(document).on('change', ".payment-methods input[type='radio']", function () {
        let value = $(this).val()
        if ($(this).prop('checked') && value === "new_payment_method") {
            cardDetailField.classList.remove('d-none')
            if (!$('#payment-element').hasClass('StripeElement')) {
                initialize()
            }
        } else {
            cardDetailField.classList.add('d-none')
        }
    })

    function initialize() {
        $.ajax({
            url: "/payments/stripe_publishable_key",
            data: {
                authenticity_token: token
            },
            method: 'get'
        }).done(function (res) {
            if (res['error']) {
                alert(res['error'])
            } else {
                var key = res['key']
                createPaymentIntent(key)
            }
        }).fail(function (res) {
            alert('Something went wrong')
        });
    }

    function createPaymentIntent(publishable_key) {
        $.ajax({
            url: `/payments/${paymentId}/create_payment_intent`,
            data: {
                authenticity_token: token
            },
            method: 'post'
        }).done(function (res) {
            if (res['error']) {
                alert(res['error'])
            } else {
                var clientSecret = res['client_secret']
                var domainUrl = res['domain_url']
                var connectAccountId = res['connect_account_id']
                var stripe = createStripeClient(publishable_key, connectAccountId)
                loadForm(stripe, clientSecret, domainUrl)
            }
        }).fail(function (res) {
            alert('Something went wrong')
        });
    }

    function createStripeClient(key, connectAccountId) {
        // Create a Stripe client.
        return Stripe(key, {stripeAccount: connectAccountId});
    }

    function loadForm(stripe, clientSecret, domainUrl) {
        // Create an instance of Elements.
        var elements = stripe.elements({clientSecret});

        // Create an instance of the payment Element.
        var paymentElement = elements.create('payment');

        // Add an instance of the payment Element into the `payment-element` <div>.
        paymentElement.mount('#payment-element');

        formSubmission(stripe, elements, domainUrl)
    }

    function formSubmission(stripe, elements, domainUrl) {
        // Handle form submission.
        form.addEventListener('submit', async function (event) {
            var paymentMethodValue = form.querySelector(".payment-methods input[type='radio']:checked").value
            if (paymentMethodValue === 'new_payment_method') {
                event.preventDefault();

                var cardName = form.querySelector('#card_name').value
                var return_url = `${domainUrl}/payments/${paymentId}/checkout`
                if (cardName.length > 0) {
                    return_url = `${return_url}?name=${cardName}`
                }
                const {error} = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        return_url: return_url,
                    }
                });

                if (error) {
                    alert(error.message);
                }
            }
        });
    }
});