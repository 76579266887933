document.addEventListener('turbolinks:load', function () {
    if ($('.permit_applications.new, .permit_applications.create, .projects.edit, .permit_applications.update, .permit_applications.edit, .projects.new').length === 0) {
        return false
    }

    // do not want to trigger this js if we are working on project edit form
    // it should only serve the permit applications form
    if($('.permit_applications-form-section').length === 0) {
        return false
    }

    let permitApplicationForm = $("#permit-application-form")
    processStep();
    handleNextStepId();

    function processStep() {
        $('.previous-step-btn').on('click', function (e) {
            e.preventDefault();

            var targetId = $(this).data("target")
            $(targetId).click();
        });

        $('.next-step-btn').on('click', function (e) {
            e.preventDefault();

            let currentSection = $(`#${$(e.target).data('current')}`);
            var targetId = $(this).data("target")

            if (currentSection.length !== 0) {
                let currentSectionId = currentSection.attr('id');
                if (currentSectionId === 'constructionSelectionTab') {
                    if($("#constructionSelectionTab input:radio:checked").length === 0) {
                        fireError(e)
                    } else {
                        submitForm()
                    }
                } else if (currentSectionId === 'projectTypeSelectionTab') {
                    if($("#projectTypeSelectionTab input:radio:checked").length === 0) {
                        fireError(e)
                    } else {
                        submitForm()
                    }
                } else if (currentSectionId === 'permitInformationTab') {
                    if($("#permitInformationTab input:checkbox:checked").length === 0) {
                        fireError(e)
                    } else {
                        handleRequiredFields(e, targetId, currentSection)
                    }
                } else {
                    handleRequiredFields(e, targetId, currentSection)
                }
            } else {
                submitForm()
            }
        });

        $('.create-project-now-btn, .proceed-to-checkout-btn').on('click', function (e) {
            // determines if after project creation if user should be redirected to checkout page or
            // project folder page
            var projectCreationAction = $(this).data("project-creation-action")
            if(projectCreationAction && projectCreationAction.length !== 0) {
                permitApplicationForm.append('<input type="hidden" name="project_creation_action" id="project_creation_action" value=""/>')
                permitApplicationForm.find("#project_creation_action").val(projectCreationAction)
            }
        });
    }

    function handleRequiredFields(e, targetId, currentSection) {
        let allRequiredFields = currentSection.find('.requiredField');
        if (!(areAllRequiredFieldsFilled(allRequiredFields))) {
            fireError(e)
        } else {
            submitForm()
        }
    }

    function fireError(e) {
        Swal.fire({
            icon: 'error',
            title: 'Cannot continue...',
            text: 'Please fill all the required fields!'
        })
        e.stopPropagation();
        e.preventDefault();
    }

    function areAllRequiredFieldsFilled(fields) {
        var isValid = true;
        $(fields).each(function () {
            if ($(this).val() === '')
                isValid = false;
        });
        return isValid;
    }

    function submitForm() {
        let form = document.querySelector('#permit-application-form');
        let btn = document.createElement('input');
        btn.style.display = 'none';
        btn.type = 'submit';
        form.appendChild(btn).click();
        form.removeChild(btn);
    }

    function handleNextStepId() {
        var constructionTypeTab = document.querySelector('#v-construction-type-tab')
        var projectTypeTab = document.querySelector('#v-project-type-tab')
        var projectDetailsTab = document.querySelector('#v-project-details-tab')
        var permitInformationTab = document.querySelector('#v-permit-information-tab')
        if(constructionTypeTab) {
            shownTabHandler(constructionTypeTab, '#v-project-type-tab')
        }
        if(projectTypeTab) {
            shownTabHandler(projectTypeTab, '#v-project-details-tab')
        }
        if(projectDetailsTab) {
            shownTabHandler(projectDetailsTab, '#v-permit-information-tab')
        }
        if(permitInformationTab) {
            shownTabHandler(permitInformationTab, '#v-permit-summary-tab')
        }
    }

    function shownTabHandler(tab, targetId) {
        tab.addEventListener('shown.bs.tab', function () {
            assignNextStep(targetId)
        })
    }

    function assignNextStep(targetId) {
        // project next step determines where the user should be directed when next button is clicked
        // the value is sent to backend
        permitApplicationForm.find("#project_project_next_step_id").val(targetId)
    }
});