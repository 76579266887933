document.addEventListener('turbolinks:load', function () {
    if ($('.permit_applications.new, .permit_applications.create, .projects.edit, .permit_applications.update, .permit_applications.edit, .projects.new').length === 0) {
        return false
    }

    // do not want to trigger this js if we are working on project edit form
    // it should only serve the permit applications form
    if ($('.permit_applications-form-section').length === 0) {
        return false
    }

    var permitSummaryTab = document.querySelector('#v-permit-summary-tab')
    permitSummaryTab.addEventListener('shown.bs.tab', function () {
        populateProjectNameAddress();
        handlePermitSelection();
    })

    function populateProjectNameAddress() {
        var projectSummaryDetailSection = $('#permitInformationTab .summary-card .project-detail-section')
        var projectName = projectSummaryDetailSection.find('.name').text()
        var address = projectSummaryDetailSection.find('.address').text()
        var projectDetailSection = $('#permitSummaryTab .summary-card .project-detail-section')
        projectDetailSection.find('.name').text(projectName)
        projectDetailSection.find('.address').text(address)
    }

    function handlePermitSelection() {
        let selectedPermits = $('#permitInformationTab .permit-checkbox input:checkbox:checked')

        if (selectedPermits.length > 0) {
            var permits = []
            selectedPermits.each(function () {
                var checkboxValue = $(this).val();
                if (checkboxValue === 'Other') {
                    let otherPermit = $('#project_other_permit').val()
                    if (otherPermit.length > 0) {
                        permits.push(otherPermit)
                    } else {
                        permits.push(checkboxValue)
                    }
                } else {
                    permits.push(checkboxValue)
                }
            })

            calculateFees(permits)
        }
    }

    function calculateFees(permits) {
        var token = $("meta[name='csrf-token']").attr('content');
        var constructionType = $("#projectTypeSelectionTab input:radio:checked").val() || $('#project_type_of_construction').val()
        var intendedUseOfParcel = $("#constructionSelectionTab input:radio:checked").val() || $('#project_intended_use_of_parcel').val()
        var permitInformationTab = $('#permitInformationTab')
        var totalSqFt = permitInformationTab.find('#project_total_sq_ft').val()
        var valuation = permitInformationTab.find('#project_valuation_number').val()
        var organizationId = $('#project_organization_id').val()

        $.ajax({
            url: "/permit_applications/permit_fees",
            data: {
                permits: permits,
                construction_type: constructionType,
                intended_use_of_parcel: intendedUseOfParcel,
                total_sq_ft: totalSqFt,
                valuation: valuation,
                organization_id: organizationId,
                authenticity_token: token
            },
            method: 'get'
        }).done(function (res) {
            if (res['error']) {
                alert(res['error'])
            } else {
                var permitFees = res['permit_fees']
                displayPermitFees(permitFees)
            }
        }).fail(function (res) {
            alert('Something went wrong')
        });
    }

    function displayPermitFees(permitFees) {
        let cardBody = $('#permitSummaryTab .summary-card .body')
        let permitSection = cardBody.find('.permit-section')
        permitSection.empty()
        for ([permit, fee_response] of Object.entries(permitFees)) {
            let element = document.createElement('div')
            element.classList.add('selected-permit')

            let permitElement = document.createElement('div')
            permitElement.innerHTML = permit;
            element.append(permitElement)

            let feeElement = document.createElement('div')
            if (fee_response.error) {
                feeElement.innerHTML = fee_response.error
            } else if (fee_response.status) {
                feeElement.innerHTML = fee_response.status
            } else {
                feeElement.innerHTML = "$" + fee_response.fee
            }

            element.append(feeElement)

            permitSection.append(element);
        }
    }
});